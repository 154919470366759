import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import auth from './pages/Login/reducer';
import main from './layouts/reducer';
import users from './pages/Users/reducer';
import blacklist from './pages/Blacklist/reducer';
import networksBlacklist from './pages/NetworksBlacklist/reducer';
import walletLimits from './pages/WalletLimits/reducer';
import accounts from './pages/Accounts/reducer';
import transactions from './pages/Transactions/reducer';
import providers from './pages/Providers/reducer';
import msb from './pages/MSB/reducer';

const reducer = combineReducers({
  routing: routerReducer,
  auth,
  main,
  users,
  blacklist,
  networksBlacklist,
  walletLimits,
  accounts,
  transactions,
  providers,
  msb
});

export default reducer;
