import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';
import { Router, Switch, Route as LoginRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import configureStore from './utils/store';
import PrivateRoute from './layouts/components/PrivateRoute';
import LoginPage from './pages/Login/container';
import TransactionsPage from './pages/Transactions/container';
// import UsersPage from './pages/Users/container';
// import BlacklistPage from './pages/Blacklist/container';
// import NetworksBlacklistPage from './pages/NetworksBlacklist/container';
// import WalletLimitsPage from './pages/WalletLimits/container';
// import AccountsPage from './pages/Accounts/container';
// import ProvidersPage from './pages/Providers/container';
// import MSBPage from './pages/MSB/container';
// import NotImplemented from './pages/NotImplemented/container';
import NoMatchPage from './pages/NoMatch/container';
import GlobalStyle from './indexStyled';

const store = configureStore();
const history = syncHistoryWithStore(createBrowserHistory(), store);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <LoginRouter path="/login" component={LoginPage} />
        <PrivateRoute exact path="/" component={TransactionsPage} />
        <PrivateRoute exact path="/transactions" component={TransactionsPage} />
        {/* <PrivateRoute exact path="/users" component={UsersPage} /> */}
        {/* <PrivateRoute exact path="/blacklist" component={BlacklistPage} /> */}
        {/* <PrivateRoute exact path="/networks_blacklist" component={NetworksBlacklistPage} /> */}
        {/* <PrivateRoute exact path="/wallet_limit" component={WalletLimitsPage} /> */}
        {/* <PrivateRoute exact path="/accounts" component={AccountsPage} /> */}
        {/* <PrivateRoute exact path="/providers" component={ProvidersPage} /> */}
        {/* <PrivateRoute exact path="/msb" component={MSBPage} /> */}
        {/* Not Implemented */}
        {/* <PrivateRoute exact path="/reports" component={NotImplemented} />
        <PrivateRoute exact path="/settings" component={NotImplemented} /> */}
        {/* No Match */}
        <PrivateRoute component={NoMatchPage} />
      </Switch>
      <GlobalStyle />
    </Router>
  </Provider>,
  document.getElementById('root')
);
