import { showError, showSuccess } from '../../layouts/actions';
import axiosInstance from '../../utils/axios';

export const PROVIDERS_SET_LIST = 'PROVIDERS_SET_LIST';
export const PROVIDERS_SET_PAGE = 'PROVIDERS_SET_PAGE';
export const PROVIDERS_SET_SORT = 'PROVIDERS_SET_SORT';
export const PROVIDERS_SET_FILTERS = 'PROVIDERS_SET_FILTERS';
export const PROVIDERS_RESET = 'PROVIDERS_RESET';

export const fetchData = () => (dispatch, getState) => {
  const state = getState();
  const { filters, paging, sort } = state.providers;
  dispatch(getProviders(filters, paging, sort));
};

const getTransactionsParams = (filters, paging, sort) => {
  const { currentPage, pageSize } = paging;
  const skip = currentPage * pageSize;
  return {
    limit: pageSize,
    offset: skip,
    filters,
    sort_field: sort.field,
    sort_order: sort.direction
  };
};

export const getProviders = (filters, paging, sort) => async dispatch => {
  try {
    const res = await axiosInstance.post('/providers/list', {
      ...getTransactionsParams(filters, paging, sort)
    });
    dispatch({
      type: PROVIDERS_SET_LIST,
      data: res.data.list || [],
      total: res.data.total || 0
    });
  } catch (err) {
    dispatch(showError(err));
  }
};

export const createProvider = data => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/providers/add', {
      ...data
    });
    dispatch(showSuccess('Provider is created!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const activateProvider = item => async dispatch => {
  try {
    await axiosInstance.post(`/providers/activate/${item.id}`);
    dispatch(showSuccess('Provider is activated!'));
    dispatch(fetchData());
  } catch (err) {
    dispatch(showError(err));
  }
};

export const deactivateProvider = item => async dispatch => {
  try {
    await axiosInstance.post(`/providers/deactivate/${item.id}`);
    dispatch(showSuccess('Provider is deactivated!'));
    dispatch(fetchData());
  } catch (err) {
    dispatch(showError(err));
  }
};

export const editProvider = data => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/providers/update', {
      ...data
    });
    dispatch(showSuccess('Provider is updated!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const deleteProvider = item => async dispatch => {
  try {
    await axiosInstance.post('/providers/delete', {
      id: item.id
    });
    dispatch(showSuccess('Provider is deleted!'));
    dispatch(fetchData());
  } catch (err) {
    dispatch(showError(err));
  }
};

export const changePage = page => dispatch => {
  dispatch({ type: PROVIDERS_SET_PAGE, currentPage: page });
  dispatch(fetchData());
};

export const changeSort = sort => dispatch => {
  dispatch({ type: PROVIDERS_SET_SORT, sort });
  dispatch(fetchData());
};

export const changeFilters = filters => dispatch => {
  dispatch({ type: PROVIDERS_SET_FILTERS, filters });
  dispatch({ type: PROVIDERS_SET_PAGE, currentPage: 0 });
  dispatch(fetchData());
};

export const resetData = () => dispatch => {
  dispatch({ type: PROVIDERS_RESET });
};
