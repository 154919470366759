import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { removeCookiesToken, getNetwork } from '../../layouts/actions';
import { auth } from './actions';
import LoginForm from './components/Form';

// @TODO: refactoring: use hooks
class Users extends Component {
  componentDidMount() {
    removeCookiesToken();
    const { dispatch } = this.props;
    dispatch(getNetwork());
  }

  onLogin = data => {
    const { dispatch, history } = this.props;
    dispatch(auth(data, history));
  };

  render() {
    const { auth, main } = this.props;
    const { error } = auth;
    const { networkID } = main;
    return (
      <div>
        <LoginForm onLogin={this.onLogin} error={error} networkID={networkID} />
      </div>
    );
  }
}

Users.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    main: state.main
  };
}

export default connect(mapStateToProps)(Users);
