import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dataFromServer, quantityFromServer } from '../../../utils/main';
import OverflowCopiedStr from '../../../components/OverflowCopiedStr';
import { DESC } from '../../../constants';

const TableRow = ({ item, isPayForTestBatch }) => {
  const {
    status,
    hash,
    from,
    to,
    amount,
    // value,
    // request_time,
    // block_number,
    type,
    block_time,
    contract_call_info
  } = item;

  const statusObj = DESC.STATUSES.find(item => item.value === +status) || {};

  return (
    <StyledTr color={statusObj.color}>
      <td>{dataFromServer(block_time)}</td>
      <td>
        <OverflowCopiedStr str={hash} />
      </td>
      <td>
        <OverflowCopiedStr str={from} />
      </td>
      <td>
        <OverflowCopiedStr str={to} strReplacement="" />
      </td>
      {isPayForTestBatch && <td>
        <OverflowCopiedStr
          str={quantityFromServer(amount)}
          strReplacement={quantityFromServer(amount)}
          isOverflow={false}
        />
      </td>}
      <td>
        <StyledStatusColored color={statusObj.color}>
          {statusObj.label || status}
        </StyledStatusColored>
      </td>
      {/* <td>{dataFromServer(request_time)}</td> */}
      {/* <td>{block_number}</td> */}
      <td>{(type || "").toUpperCase()}</td>
      <td className="info">{
        contract_call_info && contract_call_info.input
          ? JSON.stringify(JSON.parse(contract_call_info.input), null, 2)
          : ""
      }</td>
    </StyledTr>
  );
};

const StyledStatusColored = styled.div`
  color: ${props => props.color};
  font-weight: bold;
`;

const StyledTr = styled.tr`
  > td {
    border-top: none !important;
    &:first-child {
      border-left: 4px solid ${props => props.color};
    }
    &.info {
      max-width: 200px;
      word-break: break-all;
    }
  }
`;

TableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.number,
    hash: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    // value: PropTypes.string,
    // request_time: PropTypes.number,
    // block_number: PropTypes.number,
    block_time: PropTypes.number,
    contract_call_info: PropTypes.string,
    isPayForTestBatch: PropTypes.bool
  })
};

export default TableRow;
