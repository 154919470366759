import { showError } from '../../layouts/actions';
import axiosInstance from '../../utils/axios';

export const TRANSACTIONS_SET_LIST = 'TRANSACTIONS_SET_LIST';
export const TRANSACTIONS_SET_PAGE = 'TRANSACTIONS_SET_PAGE';
export const TRANSACTIONS_SET_SORT = 'TRANSACTIONS_SET_SORT';
export const TRANSACTIONS_SET_FILTERS = 'TRANSACTIONS_SET_FILTERS';
export const TRANSACTIONS_RESET = 'TRANSACTIONS_RESET';

export const fetchData = () => (dispatch, getState) => {
  const state = getState();
  const { filters, paging, sort } = state.transactions;
  dispatch(getTransactions(filters, paging, sort));
};

const getTransactionsParams = (filters, paging, sort) => {
  const { currentPage, pageSize } = paging;
  const skip = currentPage * pageSize;
  return {
    limit: pageSize,
    offset: skip,
    filters,
    sort_field: sort.field,
    sort_order: sort.direction
  };
};

export const getTransactions = (filters, paging, sort) => async dispatch => {
  try {
    const res = await axiosInstance.post('/transactions/list', {
      ...getTransactionsParams(filters, paging, sort)
    });
    dispatch({
      type: TRANSACTIONS_SET_LIST,
      data: res.data.list || [],
      total: res.data.total || res.data.Total || 0
    });
  } catch (err) {
    dispatch(showError(err));
  }
};

export const changePage = page => dispatch => {
  dispatch({ type: TRANSACTIONS_SET_PAGE, currentPage: page });
  dispatch(fetchData());
};

export const changeSort = sort => dispatch => {
  dispatch({ type: TRANSACTIONS_SET_SORT, sort });
  dispatch(fetchData());
};

export const changeFilters = filters => dispatch => {
  dispatch({ type: TRANSACTIONS_SET_FILTERS, filters });
  dispatch({ type: TRANSACTIONS_SET_PAGE, currentPage: 0 });
  dispatch(fetchData());
};

export const resetData = () => dispatch => {
  dispatch({ type: TRANSACTIONS_RESET });
};
