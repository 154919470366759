import React from 'react';
import propTypes from 'prop-types';
import Form from './Form';

const FiltersForm = ({ children, onSubmit }) => (
  <Form onSubmit={onSubmit} submitMd={1} submitClassName="btn-filter" submitTitle="Search">
    {children}
  </Form>
);

FiltersForm.propTypes = {
  onSubmit: propTypes.func.isRequired
};

export default FiltersForm;
