import config from '../../config';

import {
  WALLET_LIMITS_TOGGLE_LOADING,
  WALLET_LIMITS_SET_LIST,
  WALLET_LIMITS_SET_ASSETS,
  WALLET_LIMITS_UPDATE_ASSETS,
  WALLET_LIMITS_SET_ASSET_PAGE,
  WALLET_LIMITS_SEARCH_ASSETS,
  WALLET_LIMITS_ASSETS_CLEAR,
  WALLET_LIMITS_SET_PAGE,
  WALLET_LIMITS_SET_SORT,
  WALLET_LIMITS_SET_FILTERS,
  WALLET_LIMITS_RESET
} from './actions';

const initialState = {
  list: [],
  total: 0,
  sort: {},
  filters: [],
  paging: {
    currentPage: 0,
    pageSize: config.pageSize
  },
  loading: {
    addEditModalBtn: false,
    editModal: false
  },
  assets: {
    list: [],
    total: 0,
    options: [],
    search: '',
    paging: {
      currentPage: 0,
      pageSize: config.autoCompleteSize
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case WALLET_LIMITS_TOGGLE_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.block]: action.loading
        }
      };
    }

    case WALLET_LIMITS_SET_ASSETS: {
      return {
        ...state,
        assets: {
          ...state.assets,
          list: action.data,
          total: action.total,
          options: action.data.map(item => {
            return {
              label: `${item.code}: ${item.name}`,
              value: item.code
            };
          })
        }
      };
    }

    case WALLET_LIMITS_UPDATE_ASSETS: {
      return {
        ...state,
        assets: {
          ...state.assets,
          list: [...state.assets.list, ...action.data]
        }
      };
    }

    case WALLET_LIMITS_SET_ASSET_PAGE: {
      return {
        ...state,
        assets: {
          ...state.assets,
          paging: {
            ...state.assets.paging,
            currentPage: action.currentPage
          }
        }
      };
    }

    case WALLET_LIMITS_SEARCH_ASSETS: {
      return {
        ...state,
        assets: {
          ...state.assets,
          search: action.search
        }
      };
    }

    case WALLET_LIMITS_ASSETS_CLEAR: {
      return {
        ...state,
        assets: initialState.assets
      };
    }

    case WALLET_LIMITS_SET_LIST: {
      return {
        ...state,
        list: action.data,
        total: action.total
      };
    }

    case WALLET_LIMITS_SET_PAGE: {
      return {
        ...state,
        paging: {
          ...state.paging,
          currentPage: action.currentPage
        }
      };
    }

    case WALLET_LIMITS_SET_SORT: {
      return {
        ...state,
        sort: action.sort
      };
    }

    case WALLET_LIMITS_SET_FILTERS: {
      return {
        ...state,
        filters: action.filters
      };
    }

    case WALLET_LIMITS_RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
