import { showError, showSuccess } from '../../layouts/actions';
import axiosInstance from '../../utils/axios';

export const ACCOUNTS_SET_LIST = 'ACCOUNTS_SET_LIST';
export const ACCOUNTS_SET_PAGE = 'ACCOUNTS_SET_PAGE';
export const ACCOUNTS_SET_SORT = 'ACCOUNTS_SET_SORT';
export const ACCOUNTS_SET_FILTERS = 'ACCOUNTS_SET_FILTERS';
export const ACCOUNTS_RESET = 'ACCOUNTS_RESET';

export const fetchData = () => (dispatch, getState) => {
  const state = getState();
  const { filters, paging, sort } = state.accounts;
  dispatch(getAccounts(filters, paging, sort));
};

const getTransactionsParams = (filters, paging, sort) => {
  const { currentPage, pageSize } = paging;
  const skip = currentPage * pageSize;
  return {
    limit: pageSize,
    offset: skip,
    filters,
    sort_field: sort.field,
    sort_order: sort.direction
  };
};

export const getAccounts = (filters, paging, sort) => async dispatch => {
  try {
    const res = await axiosInstance.post('/accounts/list', {
      ...getTransactionsParams(filters, paging, sort)
    });
    dispatch({
      type: ACCOUNTS_SET_LIST,
      data: res.data.list || [],
      total: res.data.total || 0
    });
  } catch (err) {
    dispatch(showError(err));
  }
};

export const createAccount = data => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/accounts/add', {
      ...data
    });
    dispatch(showSuccess('Account is created!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const setAccountAsAdmin = (item, isAdmin) => async dispatch => {
  try {
    await axiosInstance.post('/accounts/update', {
      id: item.id,
      is_admin: isAdmin
    });
    dispatch(showSuccess('Account make admin!'));
    dispatch(fetchData());
  } catch (err) {
    dispatch(showError(err));
  }
};

export const deleteAccount = item => async dispatch => {
  try {
    await axiosInstance.post('/accounts/delete', {
      id: item.id
    });
    dispatch(showSuccess('Account is deleted!'));
    dispatch(fetchData());
  } catch (err) {
    dispatch(showError(err));
  }
};

export const changePage = page => dispatch => {
  dispatch({ type: ACCOUNTS_SET_PAGE, currentPage: page });
  dispatch(fetchData());
};

export const changeSort = sort => dispatch => {
  dispatch({ type: ACCOUNTS_SET_SORT, sort });
  dispatch(fetchData());
};

export const changeFilters = filters => dispatch => {
  dispatch({ type: ACCOUNTS_SET_FILTERS, filters });
  dispatch({ type: ACCOUNTS_SET_PAGE, currentPage: 0 });
  dispatch(fetchData());
};

export const resetData = () => dispatch => {
  dispatch({ type: ACCOUNTS_RESET });
};
