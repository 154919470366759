import axiosInstance from '../../utils/axios';
import { getObj } from '../../utils/main';
import { hideAllNotifications, setCookiesToken, removeCookies } from '../../layouts/actions';

export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGGED_ERROR = 'LOGGED_ERROR';
export const LOGGED_TOGGLE_LOADING = 'LOGGED_TOGGLE_LOADING';

export const toggleLoading = isLoading => dispatch => {
  dispatch({ type: LOGGED_TOGGLE_LOADING, isLoading });
};

export const redirectToLoginPage = () => {
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};

export const auth = (data, history) => async dispatch => {
  try {
    dispatch(hideAllNotifications());

    const res = await axiosInstance.post('/auth', { ...data });

    if (getObj(res, 'data.success') && getObj(res, 'data.message.token')) {
      setCookiesToken(res.data.message.token);
      dispatch({ type: LOGGED_IN });
      history.push('/');
    }
  } catch (err) {
    const responseErr = getObj(err, 'response.data.message.error');
    dispatch({ type: LOGGED_ERROR, error: responseErr || 'Auth error' });
  }
};

export const logout = () => dispatch => {
  redirectToLoginPage();
  removeCookies();
  dispatch({ type: LOGGED_OUT });
};
