import { getCookiesToken } from '../../layouts/actions';

import { LOGGED_IN, LOGGED_OUT, LOGGED_ERROR, LOGGED_TOGGLE_LOADING } from './actions';

const initialState = {
  isLogin: Boolean(getCookiesToken()),
  isLoading: Boolean(getCookiesToken()),
  error: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGGED_IN: {
      return { ...state, isLogin: true };
    }

    case LOGGED_OUT: {
      return initialState;
    }

    case LOGGED_ERROR: {
      return { ...state, error: action.error };
    }

    case LOGGED_TOGGLE_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    default: {
      return state;
    }
  }
}
