import { showError, showSuccess } from '../../layouts/actions';
import axiosInstance from '../../utils/axios';

export const NETWORKS_BLACKLIST_SET_LIST = 'NETWORKS_BLACKLIST_SET_LIST';
export const NETWORKS_BLACKLIST_SET_PAGE = 'NETWORKS_BLACKLIST_SET_PAGE';
export const NETWORKS_BLACKLIST_SET_SORT = 'NETWORKS_BLACKLIST_SET_SORT';
export const NETWORKS_BLACKLIST_SET_FILTERS = 'NETWORKS_BLACKLIST_SET_FILTERS';
export const NETWORKS_BLACKLIST_RESET = 'NETWORKS_BLACKLIST_RESET';

export const fetchData = () => (dispatch, getState) => {
  const state = getState();
  const { filters, paging, sort } = state.networksBlacklist;
  dispatch(getNetworks(filters, paging, sort));
};

const getTransactionsParams = (filters, paging, sort) => {
  const { currentPage, pageSize } = paging;
  const skip = currentPage * pageSize;
  return {
    limit: pageSize,
    offset: skip,
    filters,
    sort_field: sort.field,
    sort_order: sort.direction
  };
};

export const getNetworks = (filters, paging, sort) => async dispatch => {
  try {
    const res = await axiosInstance.post('/blacklist_networks/list', {
      ...getTransactionsParams(filters, paging, sort)
    });
    dispatch({
      type: NETWORKS_BLACKLIST_SET_LIST,
      data: res.data.list || [],
      total: res.data.total
    });
  } catch (err) {
    dispatch(showError(err));
  }
};

export const createNetwork = data => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/blacklist_networks/add', {
      ...data,
      network_id: +data.network_id
    });
    dispatch(showSuccess('Network is created!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const editNetwork = data => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/blacklist_networks/update', {
      ...data
    });
    dispatch(showSuccess('Network is updated!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const deleteNetwork = item => async dispatch => {
  try {
    await axiosInstance.post('/blacklist_networks/delete', {
      id: item.id
    });
    dispatch(showSuccess('Network is deleted!'));
    dispatch(fetchData());
  } catch (err) {
    dispatch(showError(err));
  }
};

export const changePage = page => dispatch => {
  dispatch({ type: NETWORKS_BLACKLIST_SET_PAGE, currentPage: page });
  dispatch(fetchData());
};

export const changeSort = sort => dispatch => {
  dispatch({ type: NETWORKS_BLACKLIST_SET_SORT, sort });
  dispatch(fetchData());
};

export const changeFilters = filters => dispatch => {
  dispatch({ type: NETWORKS_BLACKLIST_SET_FILTERS, filters });
  dispatch({ type: NETWORKS_BLACKLIST_SET_PAGE, currentPage: 0 });
  dispatch(fetchData());
};

export const resetData = () => dispatch => {
  dispatch({ type: NETWORKS_BLACKLIST_RESET });
};
