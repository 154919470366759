import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  copiedFromTitle,
  overflowAddress,
  overflowStr,
  replaceEmptyAddress,
  replaceStr
} from '../utils/main';
import { isValidAddress, isValidEmptyAddress } from '../utils/validation';

class OverflowCopiedStr extends Component {
  getResStr = () => {
    const { str, strReplacement, isOverflow } = this.props;

    const isAddress = isValidAddress(str);
    const isEmptyAddress = isAddress && isValidEmptyAddress(str);
    const isStrReplacement = !isAddress && strReplacement !== undefined;
    const isReplacement = isEmptyAddress || isStrReplacement;

    let res = str;
    if (isReplacement) {
      res = isEmptyAddress
        ? replaceEmptyAddress(str, strReplacement)
        : replaceStr(str, strReplacement);
    }

    if (!isEmptyAddress && !isStrReplacement && isOverflow) {
      res = isAddress ? overflowAddress(str) : overflowStr(str);
    }

    return res;
  };

  copiedFromTitle = event => {
    const { dispatch } = this.props;
    copiedFromTitle(event, dispatch);
  };

  render() {
    const { str, isStrCopy } = this.props;
    return (
      <span
        className={`${isStrCopy ? 'copied' : ''}`}
        onClick={isStrCopy ? this.copiedFromTitle : null}
        title={str}
      >
        {this.getResStr()}
      </span>
    );
  }
}

OverflowCopiedStr.propTypes = {
  dispatch: PropTypes.func.isRequired,
  str: PropTypes.string.isRequired,
  isOverflow: PropTypes.bool,
  isStrCopy: PropTypes.bool,
  strReplacement: PropTypes.string
};

OverflowCopiedStr.defaultProps = {
  isOverflow: true,
  isStrCopy: true
};

export default connect()(OverflowCopiedStr);
