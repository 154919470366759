import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Autocomplete from 'react-autocomplete';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import config from '../../config';

class AutoCompleteGroup extends Component {
  getDefaultLabel = () => {
    const { defaultValue } = this.props;
    if (!defaultValue) {
      return '';
    }
    return defaultValue.label || defaultValue;
  };

  getDefaultSelected = () => {
    const { defaultValue } = this.props;
    return defaultValue && defaultValue.label;
  };

  state = {
    value: '',
    label: this.getDefaultLabel(),
    selected: this.getDefaultSelected()
  };

  componentWillUnmount = () => {
    this.clearTimer();
  };

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  onChange = (item, input) => {
    const { value } = this.state;
    const { onChange, id } = this.props;
    onChange(id, value, item, input);
  };

  onItemSelect = (value, item) => {
    this.setState(
      {
        value: item.value || value,
        label: item.label || value,
        selected: true
      },
      () => this.onChange(item, item.label)
    );
  };

  onInputChange = event => {
    const { value } = event.target;
    this.setState(
      {
        value: '',
        label: value,
        selected: false
      },
      () => {
        this.onChange(null, value);
        this.setTimer();
      }
    );
  };

  setTimer = () => {
    const { label } = this.state;
    const { onSearch } = this.props;
    this.clearTimer();
    this.timer = setTimeout(() => {
      onSearch(label);
    }, config.timeoutAutoCompleteChange);
  };

  matchItemToTerm(state, value) {
    const res = state.label || state;
    return res.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }

  getWrapperStyles() {
    return {
      display: 'block',
      position: 'relative'
    };
  }

  isLoadMore = () => {
    const { selected } = this.state;
    const { options, totalOptions } = this.props;
    return !selected && totalOptions > options.length;
  };

  render() {
    const {
      label,
      placeholder,
      disabled,
      options,
      error,
      withLoadMoreBtn,
      totalOptions,
      onLoadMore
    } = this.props;

    return (
      <StyledAutoCompleteGroup>
        {label ? <ControlLabel>{label}</ControlLabel> : null}
        <Autocomplete
          value={this.state.label}
          onChange={this.onInputChange}
          onSelect={this.onItemSelect}
          getItemValue={item => item.label}
          items={options}
          renderMenu={children => (
            <div className="auto-complete-list">
              {children}
              {withLoadMoreBtn && this.isLoadMore() ? (
                <div
                  key="load-more"
                  className="auto-complete-item load-more-item"
                  onClick={onLoadMore}
                >
                  Load more (total: {totalOptions})
                </div>
              ) : null}
            </div>
          )}
          renderItem={(item, isHighlighted) => (
            <div
              id={item.value || item}
              key={item.value || item}
              className="auto-complete-item"
              style={{ background: isHighlighted ? 'lightgray' : 'white' }}
            >
              {item.label || item}
            </div>
          )}
          shouldItemRender={this.matchItemToTerm}
          wrapperStyle={this.getWrapperStyles()}
          inputProps={{ className: 'form-control', placeholder, disabled }}
        />
        {error ? <p className="err-msg">{error}</p> : null}
      </StyledAutoCompleteGroup>
    );
  }
}

const StyledAutoCompleteGroup = styled(FormGroup)`
  .auto-complete-list {
    border: 1px solid rgb(206, 212, 218);
    max-height: 100px;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 1;
    .auto-complete-item {
      cursor: pointer;
      padding: 5px 10px;
    }
    .load-more-item {
      background: #fff;
      border: 1px solid #ced4da;
      color: #337ab7;
      font-weight: bold;
      text-align: center;
      &:hover {
        color: #286090;
      }
    }
  }
`;

AutoCompleteGroup.propTypes = {
  id: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  optionLabel: PropTypes.string,
  error: PropTypes.string,
  withLoadMoreBtn: PropTypes.bool,
  totalOptions: PropTypes.number,
  onLoadMore: PropTypes.func,
  onSearch: PropTypes.func
};

export default AutoCompleteGroup;
