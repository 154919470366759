import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

class Paging extends Component {
  handlePageClick = event => {
    const { dispatch, onChangePage } = this.props;
    const { selected } = event;
    dispatch(onChangePage(selected));
  };

  render() {
    const { total, paging } = this.props;
    const { currentPage, pageSize } = paging;

    const pageCount = Math.ceil(total / pageSize);

    return (
      <StyledWrapPaginate>
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel={<span>...</span>}
          pageCount={pageCount}
          forcePage={currentPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination pull-right'}
          activeClassName={'active'}
        />
      </StyledWrapPaginate>
    );
  }
}

const StyledWrapPaginate = styled.div`
  .pagination > li {
    > a {
      background-color: #fff;
      border: 1px solid #ddd;
      cursor: pointer;
      float: left;
      line-height: 1.42857143;
      margin-left: -1px;
      padding: 6px 12px;
      position: relative;
      text-decoration: none;
      &:hover,
      &:focus {
        outline: none;
      }
    }
    &.active a {
      color: #fff;
      cursor: default;
      background-color: #337ab7;
      border-color: #337ab7;
    }
    &.disabled a {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
    }
    &.previous a {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.next a {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

Paging.propTypes = {
  total: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired
};

export default connect()(Paging);
