import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';

const Form = ({
  onSubmit,
  submitTitle,
  isSubmitDisabled = false,
  submitMd,
  submitClassName,
  children
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Row className="align-items-end">
        {children}

        {submitTitle && (
          <Col md={submitMd || 12}>
            <Button
              type="submit"
              className={`btn-primary ${submitClassName || ''}`}
              disabled={isSubmitDisabled}
            >
              {submitTitle}
            </Button>
          </Col>
        )}
      </Row>
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
  isSubmitDisabled: PropTypes.bool,
  submitMd: PropTypes.number,
  submitClassName: PropTypes.string
};

export default Form;
