export const TITLE_WITH_NETWORK = 'Auditor\'s dashboard';

export const DESC = {
  PERIOD_TYPES: [
    { label: 'Days', value: 'd' },
    { label: 'Hours', value: 'h' },
    { label: 'Minutes', value: 'm' },
    { label: 'Seconds', value: 's' }
  ],
  STATUSES: [
    { label: 'Pending', value: -1, color: '#f6c32f' },
    { label: 'Fail', value: 0, color: '#e33444' },
    { label: 'Success', value: 1, color: '#3f9d54' }
  ],
  STATES: [
    { label: 'New', value: 'new', color: '#337ab7' },
    { label: 'Approved', value: 'approved', color: '#3f9d54' },
    { label: 'Stopped', value: 'stopped', color: '#e33444' }
  ],
  TYPES: [
    {label: 'Initialize', value: 'initialize'},
    {label: 'Burn', value: 'burn'},
    {label: 'Transaction', value: 'transaction'},
    {label: 'Mint', value: 'mint'},
    {label: 'PayForTest', value: 'payForTest'},
    {label: 'PayForTestBatch', value: 'payForTestBatch'},
    {label: 'TransferOwnership', value: 'transferOwnership'},
    {label: 'EnableCreatorRole', value: 'enableCreatorRole'},
    {label: 'EnableDistributorRole', value: 'enableDistributorRole'},
    {label: 'EnableOperatorRole', value: 'enableOperatorRole'},
    {label: 'DisableDistributorRole', value: 'disableDistributorRole'},
    {label: 'DisableCreatorRole', value: 'disableCreatorRole'},
    {label: 'DisableOperatorRole', value: 'disableOperatorRole'},
    {label: 'UpdateOperatorWallet', value: 'updateOperatorWallet'},
    {label: 'UpdateDistributorWallet', value: 'updateDistributorWallet'},
    {label: 'UpdateCreatorWallet', value: 'updateCreatorWallet'},
  ]
};

export const ERRORS = {
  REQUIRED: 'Field is required',
  INVALID_LOGIN: 'Login must have more characters',
  INVALID_PASSWORD: 'Password must have more characters',
  INVALID_ADDRESS: 'Address is not valid',
  INVALID_ASSET: 'Asset does not exist',
  INVALID_DATE: 'Date is not valid',
  INVALID_URL: 'Url is not valid',
  GROUP_FULL_REQUIRED: 'Groups value is required'
};

export const ADMIN_ROLE_NAME = 'admin';
export const APPROVED_STATE = 'approved';
export const VALID_PASSWORD_LENGTH = 4;
export const VALID_LOGIN_LENGTH = 5;
export const COOKIES_TOKEN = '__Liquineq_gateway_token';
export const COOKIES_NETWORK = '__Liquineq_gateway_network';
