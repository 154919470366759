import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Paging from './Paging';
import TableHeaderCell from './TableHeaderCell';

const TableGroup = ({ headers, sort, onSort, total, paging, onChangePage, children }) => {
  const tableHeader = (
    <tr>
      {headers &&
        headers.map((item, index) => (
          <TableHeaderCell key={index} item={item} sort={sort} onSort={onSort} />
        ))}
    </tr>
  );

  const tableEmpty = (
    <tr>
      <td colSpan={headers ? headers.length : 0} className="text-center">
        Table is empty
      </td>
    </tr>
  );

  return (
    <div className="wrap-table">
      <Table striped>
        <thead>{tableHeader}</thead>
        <tbody>{children.length ? children : tableEmpty}</tbody>
      </Table>
      <div className="table-footer">
        <div className="table-footer__total">
          <b>Total</b>: {total}
        </div>
        {total ? <Paging total={total} paging={paging} onChangePage={onChangePage} /> : null}
      </div>
    </div>
  );
};

TableGroup.propTypes = {
  headers: PropTypes.array.isRequired,
  sort: PropTypes.object,
  onSort: PropTypes.func,
  total: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired
};

export default TableGroup;
