import axios from 'axios';
import { getCookiesToken, removeCookies } from '../layouts/actions';
import { redirectToLoginPage } from '../pages/Login/actions';
import config from '../config';

const axiosInstance = axios.create({
  baseURL: `${config.serverHost}${config.serverPort ? `:${config.serverPort}` : ""}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(config => {
  const token = getCookiesToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      redirectToLoginPage();
      removeCookies();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
