import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { hideNotification } from '../actions';
import { AlertList } from 'react-bs-notifier';
import config from '../../config';

// @TODO: library is deprecated - it displays a warning, use another
class Notifications extends Component {
  dismissNotification = notification => {
    const { dispatch } = this.props;
    dispatch(hideNotification(notification));
  };

  render() {
    const { main } = this.props;

    return (
      <StyledNotifications className="notifications">
        <AlertList
          alerts={main.notifications}
          timeout={config.timeoutNotification}
          onDismiss={this.dismissNotification}
        />
      </StyledNotifications>
    );
  }
}

const StyledNotifications = styled.div`
  button.close {
    outline: none;
    padding-left: 20px;
    padding-bottom: 8px;
  }
  .Alert-headline-0-1-36 {
    font-size: 16px;
    font-weight: bold;
  }
  .Alert-body-0-1-37 {
    font-size: 13px;
  }

  @media (max-width: 767px) {
    .Alert-body-0-1-37 {
      word-break: break-all;
    }
  }
`;

Notifications.propTypes = {
  dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    main: state.main
  };
}

export default connect(mapStateToProps)(Notifications);
