import { showError, showSuccess } from '../../layouts/actions';
import axiosInstance from '../../utils/axios';
import { getFilterServerFormat, trimExtraSpaces } from '../../utils/main';

export const WALLET_LIMITS_TOGGLE_LOADING = 'WALLET_LIMITS_TOGGLE_LOADING';
export const WALLET_LIMITS_SET_LIST = 'WALLET_LIMITS_SET_LIST';
export const WALLET_LIMITS_SET_ASSETS = 'WALLET_LIMITS_SET_ASSETS';
export const WALLET_LIMITS_UPDATE_ASSETS = 'WALLET_LIMITS_UPDATE_ASSETS';
export const WALLET_LIMITS_SET_ASSET_PAGE = 'WALLET_LIMITS_SET_ASSET_PAGE';
export const WALLET_LIMITS_SEARCH_ASSETS = 'WALLET_LIMITS_SEARCH_ASSETS';
export const WALLET_LIMITS_ASSETS_CLEAR = 'WALLET_LIMITS_ASSETS_CLEAR';
export const WALLET_LIMITS_SET_PAGE = 'WALLET_LIMITS_SET_PAGE';
export const WALLET_LIMITS_SET_SORT = 'WALLET_LIMITS_SET_SORT';
export const WALLET_LIMITS_SET_FILTERS = 'WALLET_LIMITS_SET_FILTERS';
export const WALLET_LIMITS_RESET = 'WALLET_LIMITS_RESET';

const toggleLoading = (block, loading) => dispatch => {
  dispatch({ type: WALLET_LIMITS_TOGGLE_LOADING, block, loading });
};

export const fetchData = () => (dispatch, getState) => {
  const state = getState();
  const { filters, paging, sort } = state.walletLimits;
  dispatch(getWalletLimits(filters, paging, sort));
};

const getTransactionsParams = (filters, paging, sort) => {
  const { currentPage, pageSize } = paging;
  const skip = currentPage * pageSize;
  return {
    limit: pageSize,
    offset: skip,
    filters,
    sort_field: sort.field,
    sort_order: sort.direction
  };
};

export const getWalletLimits = (filters, paging, sort) => async dispatch => {
  try {
    const res = await axiosInstance.post('/wallet_limits/list', {
      ...getTransactionsParams(filters, paging, sort)
    });
    dispatch({
      type: WALLET_LIMITS_SET_LIST,
      data: res.data.list || [],
      total: res.data.total || 0
    });
  } catch (err) {
    dispatch(showError(err));
  }
};

export const createWalletLimit = ({ period, periodType, ...data }) => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/wallet_limits/add', {
      ...data,
      period: +period && periodType !== '-' ? `${period}${periodType}` : ''
    });
    dispatch(showSuccess('Limit is created!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const editWalletLimit = ({ period, periodType, ...data }) => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/wallet_limits/update', {
      ...data,
      period: period && periodType ? `${period}${periodType}` : ''
    });
    dispatch(showSuccess('Limit is updated!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const deleteWalletLimit = item => async dispatch => {
  try {
    await axiosInstance.post('/wallet_limits/delete', {
      id: item.id
    });
    dispatch(showSuccess('Limit is deleted!'));
    dispatch(fetchData());
  } catch (err) {
    dispatch(showError(err));
  }
};

export const changePage = page => dispatch => {
  dispatch({ type: WALLET_LIMITS_SET_PAGE, currentPage: page });
  dispatch(fetchData());
};

export const changeSort = sort => dispatch => {
  dispatch({ type: WALLET_LIMITS_SET_SORT, sort });
  dispatch(fetchData());
};

export const changeFilters = filters => dispatch => {
  dispatch({ type: WALLET_LIMITS_SET_FILTERS, filters });
  dispatch({ type: WALLET_LIMITS_SET_PAGE, currentPage: 0 });
  dispatch(fetchData());
};

// Assets
const getAssetsParams = () => (dispatch, getState) => {
  const state = getState();
  const { paging, search } = state.walletLimits.assets;
  const { currentPage, pageSize } = paging;
  const skip = currentPage * pageSize;

  let filters;
  if (search) {
    filters = ['code'].map(field => {
      const filter = getFilterServerFormat(field, 'like');
      filter.params[0].value = trimExtraSpaces(search).toUpperCase();
      return filter;
    });
  }

  return {
    limit: pageSize,
    offset: skip,
    filters
  };
};

export const getAssets = () => async dispatch => {
  try {
    const res = await axiosInstance.post('/assets/list', {
      ...dispatch(getAssetsParams())
    });
    return res.data;
  } catch (err) {
    dispatch(showError(err));
  }
};

export const fetchAssets = isFirstLoad => async dispatch => {
  try {
    dispatch(toggleLoading('addEditModalBtn', true));
    if (isFirstLoad) {
      dispatch(toggleLoading('editModal', true));
    }

    dispatch({ type: WALLET_LIMITS_SET_ASSET_PAGE, currentPage: 0 });
    const assets = await dispatch(getAssets());
    dispatch({
      type: WALLET_LIMITS_SET_ASSETS,
      data: assets.list || [],
      total: assets.total
    });

    dispatch(toggleLoading('addEditModalBtn', false));
    if (isFirstLoad) {
      dispatch(toggleLoading('editModal', false));
    }
  } catch (err) {
    dispatch(toggleLoading('addEditModalBtn', false));
    if (isFirstLoad) {
      dispatch(toggleLoading('editModal', false));
    }
    dispatch(showError(err));
  }
};

export const updateAssets = () => async dispatch => {
  try {
    dispatch(toggleLoading('addEditModalBtn', true));

    const assets = await dispatch(getAssets());
    dispatch({
      type: WALLET_LIMITS_UPDATE_ASSETS,
      data: assets.list || []
    });

    dispatch(toggleLoading('addEditModalBtn', false));
  } catch (err) {
    dispatch(toggleLoading('addEditModalBtn', false));
    dispatch(showError(err));
  }
};

export const searchAssets = search => dispatch => {
  dispatch({ type: WALLET_LIMITS_SEARCH_ASSETS, search });
};

export const changeAssetPage = page => dispatch => {
  dispatch({ type: WALLET_LIMITS_SET_ASSET_PAGE, currentPage: page });
  dispatch(updateAssets());
};

export const clearAssets = () => dispatch => {
  dispatch({ type: WALLET_LIMITS_ASSETS_CLEAR });
};

export const resetData = () => dispatch => {
  dispatch({ type: WALLET_LIMITS_RESET });
};
