import { ERRORS, VALID_LOGIN_LENGTH, VALID_PASSWORD_LENGTH } from '../constants';
import moment from 'moment';

export const VALID_ADDRESS_PATTERN = /^0[xX][a-fA-F0-9]{40}$/;
export const VALID_EMPTY_ADDRESS_PATTERN = /^0[xX]0{40}$/;
export const VALID_URL_PATTERN = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}$/;

// @TODO: remove this methods ------ ∨ ------
export const isError = error => {
  return error.length;
};
export const formHasErrors = errors => {
  return Object.keys(errors).some(fieldId => isError(errors[fieldId]));
};
export const validateForm = ({ validationFuncs, changeErrors, formState }) => {
  if (typeof changeErrors === 'function') {
    const resErrors = {};
    Object.keys(validationFuncs).forEach(fieldId => {
      resErrors[fieldId] = validationFuncs[fieldId](formState);
    });
    changeErrors({
      ...formState.errors,
      ...resErrors
    });
  }
};
// Validation functions returns error if value is not valid or empty if value is valid
export const requiredValidation = value => {
  return value && value !== '-' ? '' : ERRORS.REQUIRED;
};
// @TODO: remove this methods ------ ∧ ------

export const isValidAddress = address => {
  return VALID_ADDRESS_PATTERN.test(address);
};

export const isValidEmptyAddress = address => {
  return VALID_EMPTY_ADDRESS_PATTERN.test(address);
};

export const isValidUrl = url => {
  return VALID_URL_PATTERN.test(url);
};

export const notEmptyWalletValidation = value => {
  return isValidAddress(value) && !isValidEmptyAddress(value) ? null : ERRORS.INVALID_ADDRESS;
};

export const walletValidation = value => {
  return isValidAddress(value) ? null : ERRORS.INVALID_ADDRESS;
};

export const passwordValidation = value => {
  return value.length > VALID_PASSWORD_LENGTH ? null : ERRORS.INVALID_PASSWORD;
};

export const expiryDateValidation = value => {
  return value ? (value && moment(value).diff(moment()) > 0 ? null : ERRORS.INVALID_DATE) : null;
};

export const inOutValidation = (blockIn, blockOut) => {
  return blockIn || blockOut ? null : ERRORS.REQUIRED;
};

export const urlValidation = value => {
  return isValidUrl(value) ? null : ERRORS.INVALID_URL;
};

export const loginValidation = value => {
  return value.length > VALID_LOGIN_LENGTH ? null : ERRORS.INVALID_LOGIN;
};

export const assetValidation = (assets, assetCode) => {
  const asset = assets.find(item => item.value === assetCode);
  return asset && asset.value ? null : ERRORS.INVALID_ASSET;
};

export const getValidatePeriodErr = (amount, period, periodType) => {
  let errorMsg = null;
  if (!amount && (!period || periodType === '-')) {
    errorMsg = ERRORS.REQUIRED;
  }
  if (period && +period !== 0 && periodType === '-') {
    errorMsg = ERRORS.GROUP_FULL_REQUIRED;
  }
  return errorMsg;
};

export const amountValidation = newState => {
  const { amount, txCount, period, periodType } = newState;
  return +amount ? null : +txCount && +period && periodType !== '-' ? null : ERRORS.REQUIRED;
};

export const txCountValidation = newState => {
  const { amount, txCount, period, periodType } = newState;
  return +txCount
    ? null
    : +amount || (+txCount && +period && periodType !== '-')
    ? null
    : ERRORS.REQUIRED;
};
