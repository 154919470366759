import React from 'react';
import { Controller } from 'react-hook-form';
import FieldGroup from './FieldGroup';
import { Col } from 'react-bootstrap';
import config from '../../config';

export default ({ md, label, name, disabled, onChange, control, rules, error }) => {
  return (
    <Col md={md || 12}>
      <Controller
        as={
          <FieldGroup
            fieldType="DateTime"
            id={name}
            label={label}
            placeholder={label}
            disabled={disabled}
            format={config.dataTimeFormat}
            error={error && error.message}
          />
        }
        name={name}
        onChange={data => {
          onChange && onChange(data[1]);
          return data[1];
        }}
        control={control}
        rules={rules}
        defaultValue=""
      />
    </Col>
  );
};
