import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getCookiesToken, getUserInfo } from '../actions';
import Loader from '../../components/Loader';

class PrivateRoute extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUserInfo());
  }

  render() {
    const token = getCookiesToken();
    const { isLogin, isLoading } = this.props.auth;

    if (!token || !isLogin) {
      return <Redirect to="/login" />;
    }

    if (isLoading) {
      return <Loader />;
    }

    return <Route {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(PrivateRoute);
