import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formHasErrors, validateForm, passwordValidation } from '../../../utils/validation';
import { Form, Row, Col, Button } from 'react-bootstrap';
import FieldGroup from '../../../components/Form/FieldGroup';

// @TODO: refactoring: use hooks and `react-hook-form`
class LoginForm extends Component {
  state = {
    login: '',
    password: '',
    errors: {}
  };

  onChange = (id, value) => {
    this.setState({ [id]: value }, () => {
      this.validateForm();
    });
  };

  changeErrors = errors => {
    this.setState({ errors });
  };

  isBtnDisabled() {
    const { login, errors } = this.state;
    return !login || formHasErrors(errors);
  }

  validateForm = () => {
    validateForm({
      validationFuncs: {
        password: this.passwordValidation
      },
      changeErrors: this.changeErrors,
      formState: this.state
    });
  };

  passwordValidation(newState) {
    return passwordValidation(newState.password) || '';
  }

  onKeyPress = event => {
    if (!this.isBtnDisabled() && event.key === 'Enter') {
      this.onClickBtnLogin();
    }
  };

  onClickBtnLogin = () => {
    const { login, password } = this.state;
    const { onLogin } = this.props;
    onLogin({ login, password });
  };

  render() {
    const { login, password, errors } = this.state;
    const { error } = this.props;

    return (
      <StyledLoginBox>
        <div className="wrap-box">
          <div className="box no-match-box">
            <div className="box-header">Auditor's dashboard. Virusight Detection Tool</div>
            <div className="box-body">
              <Form>
                <p className="login-err-msg">{error}</p>
                <p>Please enter your login and password</p>
                <Row>
                  <Col md={12}>
                    <FieldGroup
                      id="login"
                      placeholder="Login"
                      value={login}
                      onChange={this.onChange}
                      onKeyPress={this.onKeyPress}
                    />
                  </Col>
                  <Col md={12}>
                    <FieldGroup
                      id="password"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={this.onChange}
                      onKeyPress={this.onKeyPress}
                      error={errors.password}
                    />
                  </Col>
                  <Col md={12}>
                    <Button
                      title={this.isBtnDisabled() ? 'Check fields' : null}
                      className="btn-primary"
                      disabled={this.isBtnDisabled()}
                      onClick={this.onClickBtnLogin}
                    >
                      Login
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </StyledLoginBox>
    );
  }
}

const StyledLoginBox = styled.div`
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .wrap-box {
    display: table-cell;
    vertical-align: middle;
  }
  .box {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: 600px;
    .box-header {
      border-bottom: 1px solid #ebedf2;
      font-size: 16px;
      font-weight: bold;
      padding: 20px 25px;
    }
    .box-body {
      padding: 50px 25px;
    }
  }
  .btn {
    width: 100%;
  }
  @media (max-width: 600px) {
    .box {
      height: 100%;
      margin: 0;
      width: 100%;
    }
  }
`;

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  networkID: PropTypes.number
};

export default LoginForm;
