import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import arrowUpActiveImg from '../assets/images/arrow-up-active.svg';
import arrowDownActiveImg from '../assets/images/arrow-down-active.svg';

const TableHeaderCell = ({ item, sort, onSort }) => {
  const currentDirection =
    sort && item.name === sort.field && sort.direction === 'desc' ? 'desc' : 'asc';
  const nextDirection = currentDirection === 'asc' ? 'desc' : 'asc';

  const onSortClick = () => {
    if (item.name && typeof onSort === 'function') {
      onSort({
        field: item.name,
        direction: nextDirection
      });
    }
  };

  return (
    <StyledHeaderCell
      isSortCell={item.name}
      onClick={onSortClick}
      title={item.name ? 'Click to sort' : ''}
      width={item.width || 'auto'}
      className={item.className || ''}
    >
      {typeof item === 'string' ? item : item.title}
      {item.name ? <StyledSortArrow /> : null}
    </StyledHeaderCell>
  );
};

const StyledHeaderCell = styled.th`
  white-space: nowrap;
  ${props =>
    props.isSortCell &&
    css`
      cursor: pointer;
    `}
`;

const StyledSortArrow = styled.div`
  display: inline-block;
  height: 10px;
  width: 10px;
  background-image: url(${arrowUpActiveImg}), url(${arrowDownActiveImg});
  background-position: right top, right bottom;
  background-repeat: no-repeat, no-repeat;
`;

TableHeaderCell.propTypes = {
  item: PropTypes.any,
  sort: PropTypes.object,
  onSort: PropTypes.func
};

export default TableHeaderCell;
