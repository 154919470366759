import moment from 'moment';
import { showSuccess } from '../layouts/actions';
import { isValidEmptyAddress, isValidAddress } from './validation';
import BigNumber from 'bignumber.js';
import config from '../config';

export const dataFromServer = time => {
  if (!time) {
    return '';
  }

  return moment
    .unix(time)
    .local()
    .format(config.dataTimeFormat);
};

export const dataToServer = time => {
  if (!time) {
    return '';
  }

  return window.moment(time, config.dataTimeFormat).unix();
};

export const quantityFromServer = quantity => {
  if (!quantity) {
    return '';
  }

  return new BigNumber(quantity).dividedBy(config.precision).toFixed(2);
};

export const replaceEmptyAddress = (str, strReplacement) => {
  return str && isValidEmptyAddress(str)
    ? strReplacement !== undefined
      ? strReplacement
      : config.emptyAddress
    : str;
};

export const replaceStr = (str, strReplacement) => {
  return str && strReplacement !== undefined ? strReplacement : str;
};

export const overflowStr = str => {
  return overflow(str, config.strVisibleSideLength, config.strVisibleSideLength);
};

export const overflowAddress = address => {
  return isValidAddress(address) && !config.isFullAddress
    ? overflow(address, config.addressLeftLength, config.addressRightLength)
    : address;
};

export const overflow = (str, leftLength, rightLength) => {
  const strLength = str && str.length;
  const minStrLength = leftLength + rightLength + 1;
  return strLength > minStrLength
    ? str.substr(0, leftLength) + '...' + str.substr(strLength - rightLength, strLength)
    : str;
};

export const copiedFromTitle = (event, dispatch) => {
  const title = event.target.getAttribute('title');
  if (title) {
    const el = document.createElement('textarea');
    el.value = title;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    if (dispatch) {
      dispatch(showSuccess(title, 'Copied!'));
    }
  }
};

export const getFiltersServerFormat = (data, getFilter) => {
  const isFunc = getFilter && typeof getFilter === 'function';

  return Object.keys(data)
    .filter(key => data[key])
    .map(key => {
      const { name, operator, value } = (isFunc && getFilter(key, data[key])) || {};

      return {
        field: name || key,
        params: [
          {
            operation: operator || '=',
            value: value || ''
          }
        ]
      };
    });
};

// @TODO: remove this method
export const getFilterServerFormat = (fieldName, operator, value) => {
  return {
    field: fieldName,
    params: [
      {
        operation: operator || '=',
        value: value || ''
      }
    ]
  };
};

export const getValuesFromFilters = filters => {
  const values = {};
  filters.forEach(item => {
    values[item.field] = item.params[0].value;
  });
  return values;
};

export const trimExtraSpaces = str => {
  return str && str.toString().replace(/^\s+|\s+$/g, '');
};

export const getObj = (obj, path) => {
  const isWrongTypeObj = typeof obj !== 'object';
  const isWrongTypeStr = typeof path !== 'string';
  if (isWrongTypeObj || isWrongTypeStr) {
    return false;
  }

  return path.split('.').reduce((currentObj, key) => {
    const isEmptyObj = typeof currentObj == 'undefined' || currentObj === null;
    return isEmptyObj ? currentObj : currentObj[key];
  }, obj);
};
