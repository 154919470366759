import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

class SelectGroup extends Component {
  render() {
    const { label, id, value, onChange, options, optionLabel, error, ...props } = this.props;
    return (
      <FormGroup>
        {label ? <ControlLabel>{label}</ControlLabel> : null}
        <FormControl componentClass="select" {...props} id={id} value={value} onChange={onChange}>
          {options &&
            options.map((option, index) => {
              return (
                <option value={option.value} key={index}>
                  {option[optionLabel || 'label']}
                </option>
              );
            })}
        </FormControl>
        {error ? <p className="err-msg">{error}</p> : null}
      </FormGroup>
    );
  }
}

SelectGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  optionLabel: PropTypes.string,
  error: PropTypes.string
};

export default SelectGroup;
