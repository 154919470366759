import { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

export default createGlobalStyle`
  body {
    background-color: #f2f3f8 !important;
    font-size: 14px !important;
    
    /* Component: Modal */
    &.modal-open {
      .modal-backdrop {
        opacity: .5;
      }
      .modal {
        display: flex !important;
        height: 100%;
        .modal-dialog {
          margin: auto;
          .modal-content {
            box-shadow: none;
          }
        }
      }
    }
  }

  .container {
    max-width: 100% !important;
  }

  .box {
    background: #fff;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    margin-bottom: 10px;
    padding: 30px;
    .box-title {
      margin-bottom: 10px;
    }
    &.filters {
      .btn-filter, .checkbox-group {
        margin-top: 25px;
      }
    }
  }

  .wrap-table {
    max-width: 100%;
    overflow-x: auto;
    .copied {
      cursor: pointer;
    }
    th.actions {
      text-align: center;
    }
    td.actions {
      text-align: right;
      width: 10%;
      white-space: nowrap;
      > * {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .form-group {
    margin-bottom: 30px !important;
    .control-label {
      height: 20px;
    }
  }
  .form-control {
    font-size: 14px !important;
    &::placeholder {
      font-size: 14px !important;
    }
  }

  .login-err-msg, .err-msg {
    font-size: 10px;
    color: #e33444;
    line-height: 11px;
    margin: 5px 0 0;
  }
  .err-msg {
    position: absolute;
  }

  .btn + .btn {
    margin-left: 5px;
  }
  .btn.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    &:hover,
    &:focus {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
  .btn.btn-primary {
    background-color: #337ab7;
    border-color: #337ab7;
    color: #fff;
    &:hover,
    &:focus {
      background-color: #286090;
      border-color: #122b40;
      color: #fff;
    }
  }
  .btn.btn-danger {
    background-color: #ac2925;
    border-color: #761c19;
    color: #fff;
    &:hover,
    &:focus {
      background-color: #c9302c;
      border-color: #761c19;
      color: #fff;
    }
  }
  .btn:disabled {
    cursor: not-allowed;
  }
  .table-footer {
    display: flex;
    justify-content: space-between;
    &__total {
      font-weight: 400;
      font-size: 14px;
      background-color: #fff;
      line-height: 1.42857143;
      padding: 6px 12px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
  }
`;
