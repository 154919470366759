import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../components/Button';

const Breadcrums = ({ title, disabled = false, onNewBtnClick }) => {
  return (
    <StyledBreadcrums aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">
          {title}
        </li>
        {typeof onNewBtnClick === 'function' ? (
          <Button title="New" className="btn-primary" disabled={disabled} onClick={onNewBtnClick} />
        ) : null}
      </ol>
    </StyledBreadcrums>
  );
};

const StyledBreadcrums = styled.nav`
  > ol {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    > li,
    li.active {
      color: #3f4047;
      font-size: 1.55rem;
      font-weight: bold;
      margin-right: 10px;
    }
  }
`;

Breadcrums.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onNewBtnClick: PropTypes.func
};

export default Breadcrums;
