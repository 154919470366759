import Cookies from 'js-cookie';
import axiosInstance from '../utils/axios';
import { logout, toggleLoading } from '../pages/Login/actions';
import { COOKIES_TOKEN, COOKIES_NETWORK, TITLE_WITH_NETWORK } from '../constants';

export const MAIN_ADD_NOTIFICATION = 'MAIN_ADD_NOTIFICATION';
export const MAIN_HIDE_NOTIFICATION = 'MAIN_HIDE_NOTIFICATION';
export const MAIN_HIDE_ALL_NOTIFICATIONS = 'MAIN_HIDE_ALL_NOTIFICATIONS';
export const MAIN_SET_NETWORK = 'MAIN_SET_NETWORK';
export const MAIN_USER_INFO = 'MAIN_USER_INFO';

export const changePageTitle = () => (dispatch, getState) => {
  const state = getState();
  if (state.main.networkID) {
    document.title = `${TITLE_WITH_NETWORK}`;
  }
};

// Notification
export const showSuccess = (message, headline) => dispatch => {
  dispatch({
    type: MAIN_ADD_NOTIFICATION,
    notificationType: 'success',
    headline: headline || 'success',
    message
  });
};

export const showError = (message, headline) => dispatch => {
  const responseErr = message.response && message.response.data && message.response.data.message;
  dispatch({
    type: MAIN_ADD_NOTIFICATION,
    notificationType: 'danger',
    headline: headline || 'error',
    message: typeof message === 'string' ? message : responseErr || 'Something went wrong!'
  });
};

export const hideNotification = notification => dispatch => {
  dispatch({ type: MAIN_HIDE_NOTIFICATION, notification });
};

export const hideAllNotifications = () => dispatch => {
  dispatch({ type: MAIN_HIDE_ALL_NOTIFICATIONS });
};

// Cookies
export const setCookiesToken = token => {
  Cookies.set(COOKIES_TOKEN, token);
};

export const setCookiesNetwork = networkID => {
  Cookies.set(COOKIES_NETWORK, networkID);
};

export const getCookiesToken = () => {
  return Cookies.get(COOKIES_TOKEN);
};

export const getCookiesNetwork = () => {
  return Cookies.get(COOKIES_NETWORK);
};

export const removeCookiesToken = () => {
  const token = Cookies.get(COOKIES_TOKEN);
  if (token) {
    Cookies.remove(COOKIES_TOKEN);
  }
};

export const removeCookiesNetwork = () => {
  const network = Cookies.get(COOKIES_NETWORK);
  if (network) {
    Cookies.remove(COOKIES_NETWORK);
  }
};

export const removeCookies = () => {
  removeCookiesToken();
  removeCookiesNetwork();
};

// Network
export const getNetworkFromServer = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/network_id');
    const networkID = res.data;

    dispatch({ type: MAIN_SET_NETWORK, networkID });
    setCookiesNetwork(networkID);
  } catch (err) {
    dispatch(showError(err));
  }
};

export const getNetwork = () => async (dispatch, getState) => {
  const state = getState();
  if (!state.main.networkID) {
    await dispatch(getNetworkFromServer());
  }

  dispatch(changePageTitle());
};

// User
export const setUserInfo = userInfo => dispatch => {
  dispatch({ type: MAIN_USER_INFO, userInfo });
};

export const getUserInfo = () => async dispatch => {
  try {
    const res = await axiosInstance.get('/me');
    dispatch(setUserInfo(res.data));
    dispatch(toggleLoading(false));
  } catch (err) {
    dispatch(logout());
    dispatch(toggleLoading(false));
  }
};
