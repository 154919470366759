import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../pages/Login/actions';
import { getNetwork } from './actions';
import { Grid } from 'react-bootstrap';
import Notifications from './components/Notifications';
import Header from './components/Header';
import Breadcrums from './components/Breadcrums';

const AppLayout = ({ title, disabled = false, onNewBtnClick, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNetwork());
  }, [dispatch]);

  return (
    <>
      <Notifications />
      <Header onLogout={() => dispatch(logout())} />
      <Grid>
        <Breadcrums title={title} disabled={disabled} onNewBtnClick={onNewBtnClick} />
        {children}
      </Grid>
    </>
  );
};

AppLayout.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onNewBtnClick: PropTypes.func,
  dispatch: PropTypes.func.isRequired
};

export default connect()(AppLayout);
