import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputGroup from './InputGroup';
import SelectGroup from './SelectGroup';
import DateTimeGroup from './DateTimeGroup';
import CheckboxGroup from './CheckboxGroup';
import AutoCompleteGroup from './AutoCompleteGroup';

class FieldGroup extends Component {
  onChange = event => {
    const { id, value } = event.target;
    const { onChange } = this.props;
    onChange(id, value);
  };

  onDateChange = ({ id, value }) => {
    const { onChange } = this.props;
    onChange(id, value);
  };

  onAutoCompleteChange = (id, value, item, input) => {
    const { onChange } = this.props;
    onChange(id, value, item, input);
  };

  onCheckedChange = event => {
    const { id, checked } = event.target;
    const { onChange } = this.props;
    onChange(id, checked);
  };

  render() {
    const { fieldType, ...props } = this.props;
    switch (fieldType) {
      case 'Select':
        return <SelectGroup {...props} onChange={this.onChange} />;
      case 'DateTime':
        return <DateTimeGroup {...props} onChange={this.onDateChange} />;
      case 'Checkbox':
        return <CheckboxGroup {...props} onChange={this.onCheckedChange} />;
      case 'AutoComplete':
        return <AutoCompleteGroup {...props} onChange={this.onAutoCompleteChange} />;
      default:
        return <InputGroup {...props} onChange={this.onChange} />;
    }
  }
}

FieldGroup.propTypes = {
  fieldType: PropTypes.string,
  onChange: PropTypes.func
};

export default FieldGroup;
