import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header } from 'lq-react-components';
import logo from '../../assets/images/virusight-logo.png'

const MENU_ITEMS = [
  // {
  //   title: 'Users',
  //   url: '/users',
  //   icon: 'user-circle'
  // },
  // {
  //   title: 'Blacklist',
  //   url: '/blacklist',
  //   icon: 'times-circle'
  // },
  // {
  //   title: 'Network Blacklist',
  //   url: '/networks_blacklist',
  //   icon: 'times-circle'
  // },
  // {
  //   title: 'Limits',
  //   url: '/wallet_limit',
  //   icon: 'warning'
  // },
  // {
  //   title: 'Accounts',
  //   url: '/accounts',
  //   icon: 'bank'
  // },
  {
    title: 'Transactions',
    url: '/transactions',
    icon: 'database',
    additionalPath: '/'
  },
  // {
  //   title: 'Providers',
  //   url: '/providers',
  //   icon: 'bank'
  // },
  // {
  //   title: 'MSB',
  //   url: '/msb',
  //   icon: 'briefcase'
  // },
  // {
  //   title: 'Reports',
  //   url: '/reports',
  //   icon: 'circle-o-notch'
  // },
  // {
  //   title: 'Settings',
  //   url: '/settings',
  //   icon: 'cog'
  // }
];

class MainHeader extends Component {
  isActiveMenuItem = item => {
    const { match } = this.props;
    const pathName = match.path;
    return item.url
      ? matchPath(pathName, { path: item.url, exact: false }) || pathName === item.additionalPath
      : false;
  };

  onMenuItemClick = item => {
    const { history } = this.props;
    history.push(item.url);
  };

  render() {
    const { onLogout } = this.props;

    return (
      <Header
        menuItems={MENU_ITEMS}
        isActiveMenuItem={this.isActiveMenuItem}
        onMenuItemClick={this.onMenuItemClick}
        isLogin
        onSingOut={onLogout}
        logoImg={logo}
      />
    );
  }
}

MainHeader.propTypes = {
  onLogout: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    main: state.main
  };
}

export default connect(mapStateToProps)(withRouter(MainHeader));
