import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchData, resetData } from './actions';
import AppLayout from '../../layouts/container';
import Filters from './components/Filters';
import Table from './components/Table';

const PAGE_TITLE = 'Transactions';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());

    return () => dispatch(resetData());
  }, [dispatch]);

  return (
    <AppLayout title={PAGE_TITLE}>
      <Filters />
      <Table />
    </AppLayout>
  );
};
