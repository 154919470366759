import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormGroup } from 'react-bootstrap';

const CheckboxGroup = ({ id, label, className, error, ...props }) => {
  return (
    <StyledCheckbox className={`${className ? className : ''} checkbox-group`}>
      <input id={id} type="checkbox" {...props} />
      {label ? (
        <label className="control-label" htmlFor={id}>
          <span>{label}</span>
        </label>
      ) : null}
      {error ? <p className="err-msg">{error}</p> : null}
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled(FormGroup)`
  input[type='checkbox'] {
    cursor: pointer;
  }
  &.lg input[type='checkbox'] {
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
  }
  label {
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px;
  }
  .err-msg {
    margin: 0 0 5px 0;
  }
`;

CheckboxGroup.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string
};

export default CheckboxGroup;
