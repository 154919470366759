import { getCookiesNetwork } from './actions';

import {
  MAIN_ADD_NOTIFICATION,
  MAIN_HIDE_NOTIFICATION,
  MAIN_HIDE_ALL_NOTIFICATIONS,
  MAIN_SET_NETWORK,
  MAIN_USER_INFO
} from './actions';

const initialState = {
  notifications: [],
  networkID: +getCookiesNetwork(),
  userInfo: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MAIN_ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: new Date().getTime(),
            type: action.notificationType,
            headline: action.headline,
            message: action.message
          }
        ]
      };
    }

    case MAIN_HIDE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification !== action.notification
        )
      };
    }

    case MAIN_HIDE_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notifications: []
      };
    }

    case MAIN_SET_NETWORK: {
      return {
        ...state,
        networkID: +action.networkID
      };
    }

    case MAIN_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo
      };
    }

    default: {
      return state;
    }
  }
}
