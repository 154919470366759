import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import useReducers from '../../../hooks/useReducers';
import { getValuesFromFilters, getFiltersServerFormat, dataToServer } from '../../../utils/main';
import { changeFilters } from '../actions';
import FiltersForm from '../../../components/Form/FiltersForm';
import FormInput from '../../../components/Form/FormInput';
import FormSelect from '../../../components/Form/FormSelect';
import FormDateTime from '../../../components/Form/FormDateTime';
import { DESC } from '../../../constants';

export default () => {
  const dispatch = useDispatch();
  const { filters } = useReducers('transactions');

  const { control, handleSubmit } = useForm({
    defaultValues: getValuesFromFilters(filters)
  });

  const getFilter = (name, value) => {
    return {
      hash: { name, operator: 'ilike', value },
      from: { name, operator: 'ilike', value },
      to: { name, operator: 'ilike', value },
      status: { name, operator: '=', value },
      type: { name, operator: '=', value },
      block_time_from: { name: 'block_time', operator: '>=', value: +dataToServer(value) },
      block_time_to: { name: 'block_time', operator: '<=', value: +dataToServer(value) }
    }[name];
  };

  const onSubmit = filters => {
    const notEmptyFilters = getFiltersServerFormat(filters, getFilter);
    dispatch(changeFilters(notEmptyFilters));
  };

  return (
    <div className="box filters">
      <p>Filters</p>
      <FiltersForm onSubmit={handleSubmit(onSubmit)}>
        <FormInput md={4} label="Hash" name="hash" control={control} />
        <FormInput md={4} label="From" name="from" control={control} />
        <FormInput md={4} label="To" name="to" control={control} />
        <FormSelect
          md={3}
          label="Status"
          name="status"
          control={control}
          options={[{ label: '-', value: '' }, ...DESC.STATUSES]}
        />
        <FormSelect
          md={3}
          label="Type"
          name="type"
          control={control}
          options={[{ label: '-', value: '' }, ...DESC.TYPES]}
        />
        {/* block_time */}
        <FormDateTime md={3} label="Block Time From" name="block_time_from" control={control} />
        <FormDateTime md={3} label="Block Time To" name="block_time_to" control={control} />
      </FiltersForm>
    </div>
  );
};
