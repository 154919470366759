import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import useReducers from '../../../hooks/useReducers';
import { changeSort, changePage } from '../actions';
import Table from '../../../components/Table';
import TableRow from './TableRow';

export default () => {
  const dispatch = useDispatch();
  const { list, total, sort, paging, filters } = useReducers('transactions');
  const isFilterByType = filters.some((filter => filter.field === 'type'));
  let isPayForTestBatch = false;

  if(isFilterByType) {
    isPayForTestBatch = filters.find((filter => filter.field === 'type'))
    .params.some(
      param => param.value === 'payForTestBatch' 
      || param.value === 'transaction'
      || param.value === 'mint'
      || param.value === 'burn'
    );
  }

  let headers = [
    { title: 'Time', name: 'block_time' },
    { title: 'Hash', name: 'hash' },
    { title: 'From', name: 'from' },
    { title: 'To', name: 'to' },
    // { title: 'Amount', name: 'value' },
    { title: 'Status', name: 'status' },
    { title: 'Type', name: 'type' },
    // { title: 'Request Time', name: 'request_time' },
    // { title: 'Block Number', name: 'block_number' },
    { title: 'Contract Call input' }
  ];

  if(isPayForTestBatch) {
    headers.splice(headers.findIndex(header => header.title === 'To') + 1, 0, { title: 'Amount', name: 'amount' });
  }

  return (
    <StyledWrapTable className="box">
      <Table
        headers={headers}
        sort={sort}
        onSort={sort => dispatch(changeSort(sort))}
        total={total}
        paging={paging}
        onChangePage={changePage}
      >
        {list.map((item, index) => {
          return <TableRow key={index} item={item} isPayForTestBatch={isPayForTestBatch} />;
        })}
      </Table>
    </StyledWrapTable>
  );
};

const StyledWrapTable = styled.div`
  .table {
    border-collapse: separate;
    border-spacing: 0 5px;
    .empty {
      background: transparent;
      > td {
        border: none;
      }
    }
  }
`;
