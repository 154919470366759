import React from 'react';
import PropTypes from 'prop-types';
import { Button as ReactButton } from 'react-bootstrap';

const Button = ({ onClick, title, className, ...props }) => {
  return (
    <ReactButton className={`${className || 'btn-default'}`} onClick={onClick} {...props}>
      {title}
    </ReactButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Button;
