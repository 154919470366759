import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinner';

export default () => (
  <StyledLoader>
    <Spinner />
  </StyledLoader>
);

const StyledLoader = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  text-align: center;
  padding: 20px;
  z-index: 3;

  .react-spinner {
    position: relative;
    width: 32px;
    height: 32px;
    display: inline-block;
    animation: fade-in 0.3s ease-in;
    top: 49%;
  }

  .react-spinner_bar {
    animation: react-spinner_spin 1.2s linear infinite;
    border-radius: 5px;
    background-color: #29d;
    position: absolute;
    width: 20%;
    height: 7.8%;
    top: 39.9%;
    left: 40%;
  }
`;
