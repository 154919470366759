import config from '../../config';

import {
  NETWORKS_BLACKLIST_SET_LIST,
  NETWORKS_BLACKLIST_SET_PAGE,
  NETWORKS_BLACKLIST_SET_SORT,
  NETWORKS_BLACKLIST_SET_FILTERS,
  NETWORKS_BLACKLIST_RESET
} from './actions';

const initialState = {
  list: [],
  total: 0,
  sort: {},
  filters: [],
  paging: {
    currentPage: 0,
    pageSize: config.pageSize
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NETWORKS_BLACKLIST_SET_LIST: {
      return {
        ...state,
        list: action.data,
        total: action.total
      };
    }

    case NETWORKS_BLACKLIST_SET_PAGE: {
      return {
        ...state,
        paging: {
          ...state.paging,
          currentPage: action.currentPage
        }
      };
    }

    case NETWORKS_BLACKLIST_SET_SORT: {
      return {
        ...state,
        sort: action.sort
      };
    }

    case NETWORKS_BLACKLIST_SET_FILTERS: {
      return {
        ...state,
        filters: action.filters
      };
    }

    case NETWORKS_BLACKLIST_RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
