import { showError, showSuccess } from '../../layouts/actions';
import axiosInstance from '../../utils/axios';
import { dataToServer } from '../../utils/main';

export const BLACKLIST_SET_LIST = 'BLACKLIST_SET_LIST';
export const BLACKLIST_SET_PAGE = 'BLACKLIST_SET_PAGE';
export const BLACKLIST_SET_SORT = 'BLACKLIST_SET_SORT';
export const BLACKLIST_SET_FILTERS = 'BLACKLIST_SET_FILTERS';
export const BLACKLIST_RESET = 'BLACKLIST_RESET';

export const fetchData = () => (dispatch, getState) => {
  const state = getState();
  const { isShowExpiry, filters, paging, sort } = state.blacklist;
  dispatch(getWallets(isShowExpiry, filters, paging, sort));
};

const getTransactionsParams = (filters, paging, sort) => {
  const { currentPage, pageSize } = paging;
  const skip = currentPage * pageSize;
  return {
    limit: pageSize,
    offset: skip,
    filters,
    sort_field: sort.field,
    sort_order: sort.direction
  };
};

export const getWallets = (isShowExpiry, filters, paging, sort) => async dispatch => {
  try {
    const res = await axiosInstance.post(
      `/blacklist_wallets/${isShowExpiry ? 'list' : 'activelist'}`,
      { ...getTransactionsParams(filters, paging, sort) }
    );
    dispatch({
      type: BLACKLIST_SET_LIST,
      data: res.data.list || [],
      total: res.data.total || 0
    });
  } catch (err) {
    dispatch(showError(err));
  }
};

export const createWallet = data => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/blacklist_wallets/add', {
      ...data,
      expiry_date: dataToServer(data.expiry_date) || null
    });
    dispatch(showSuccess('Wallet is created!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const editWallet = data => async dispatch => {
  let isSuccess = false;
  try {
    await axiosInstance.post('/blacklist_wallets/update', {
      ...data,
      expiry_date: dataToServer(data.expiry_date) || null
    });
    dispatch(showSuccess('Wallet is updated!'));
    dispatch(fetchData());
    isSuccess = true;
  } catch (err) {
    dispatch(showError(err));
  }
  return isSuccess;
};

export const deleteWallet = item => async dispatch => {
  try {
    await axiosInstance.post('/blacklist_wallets/delete', {
      id: item.id
    });
    dispatch(showSuccess('Wallet is deleted!'));
    dispatch(fetchData());
  } catch (err) {
    dispatch(showError(err));
  }
};

export const changePage = page => dispatch => {
  dispatch({ type: BLACKLIST_SET_PAGE, currentPage: page });
  dispatch(fetchData());
};

export const changeSort = sort => dispatch => {
  dispatch({ type: BLACKLIST_SET_SORT, sort });
  dispatch(fetchData());
};

export const changeFilters = data => dispatch => {
  dispatch({ type: BLACKLIST_SET_FILTERS, data });
  dispatch({ type: BLACKLIST_SET_PAGE, currentPage: 0 });
  dispatch(fetchData());
};

export const resetData = () => dispatch => {
  dispatch({ type: BLACKLIST_RESET });
};
