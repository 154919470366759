const defaultConfig = {
  serverHost: 'http://localhost',
  serverPort: 1326,

  dataTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  precision: 1,
  timeoutNotification: 5000,
  pageSize: 30,
  autoCompleteSize: 5,
  timeoutAutoCompleteChange: 1500,

  isFullAddress: false,
  addressLeftLength: 4,
  addressRightLength: 4,
  emptyAddress: 'Any',

  strVisibleSideLength: 4
};

export default (window.ENV ? { ...defaultConfig, ...window.ENV } : defaultConfig);
