import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

const InputGroup = ({ label, error, ...props }) => {
  return (
    <FormGroup>
      {label ? <ControlLabel>{label}</ControlLabel> : null}
      <FormControl {...props} />
      {error ? <p className="err-msg">{error}</p> : null}
    </FormGroup>
  );
};

InputGroup.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string
};

export default InputGroup;
