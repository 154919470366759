import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import 'bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker';

class DateTimeGroup extends Component {
  componentDidMount() {
    const { id, format, showTime, showDate } = this.props;

    const data = {
      format,
      time: showTime,
      date: showDate
    };

    window
      .$(`#${id}`)
      .bootstrapMaterialDatePicker(data)
      .on('change', (event, data) => {
        this.changeValue(event, data);
      });
  }

  componentWillUnmount() {
    const { id } = this.props;
    window.$(`#${id}`).bootstrapMaterialDatePicker('destroy');
  }

  changeValue(event, data) {
    const { onChange } = this.props;
    const id = event.currentTarget.id;
    onChange({ id, value: window.$(`#${id}`).val() });
  }

  onClear = () => {
    const { onChange, id } = this.props;
    onChange({ id, value: '' });
  };

  onImgBtnClick = () => {
    const { id } = this.props;
    window.$(`#${id}`).trigger('focus');
  };

  render() {
    const { id, label, value, disabled, placeholder, error, ...props } = this.props;
    return (
      <StyledFormGroup>
        {label ? <ControlLabel>{label}</ControlLabel> : null}
        <FormControl
          {...props}
          ref="datePicker"
          id={id}
          value={value || ''}
          disabled={disabled}
          placeholder={placeholder}
        />
        <StyledCleanBtn onClick={this.onClear}>x</StyledCleanBtn>
        <StyledImgBtn onClick={this.onImgBtnClick} className="fa fa-calendar" />
        {error ? <p className="err-msg">{error}</p> : null}
      </StyledFormGroup>
    );
  }
}

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 20px;
  position: relative;
  .form-control {
    padding-right: 40px;
  }
`;

const StyledCleanBtn = styled.span`
  align-items: center;
  bottom: 8px;
  color: #929394;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 22px;
  width: 20px;
`;

const StyledImgBtn = styled.i`
  bottom: 11px;
  color: #929394;
  cursor: pointer;
  position: absolute;
  right: 8px;
`;

DateTimeGroup.propTypes = {
  id: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  showTime: PropTypes.bool,
  showDate: PropTypes.bool,
  error: PropTypes.string
};

export default DateTimeGroup;
